"use client";

import { useEffect, useState } from "react";
import { NotificationToast } from "./NotificationToast";

export interface NotificationConfig {
  minDelay: number; // Minimum delay in milliseconds
  maxDelay: number; // Maximum delay in milliseconds
  seed?: number; // Optional seed for deterministic randomization
  position?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
  enabled?: boolean; // Whether notifications are enabled
  duration?: number; // Duration to show each notification in milliseconds
}

interface NotificationTemplate {
  title: string;
  content: string;
  avatar?: string;
  image?: string;
}

// Example notification templates
const defaultNotificationTemplates: NotificationTemplate[] = [
  {
    title: "New Trial Started! 🎉",
    content: "A law firm from Zurich just started their Whisper.it trial",
    avatar: "/testimonials/avatar-1.png",
  },
  {
    title: "Document Processed ✨",
    content: "A lawyer just processed a 50-page contract in under 2 minutes",
    avatar: "/testimonials/avatar-2.png",
  },
  {
    title: "Time Saved ⚡️",
    content: "Users saved 127 hours this week using Whisper.it",
  },
  {
    title: "New Feature Used 🚀",
    content: "15 lawyers are using voice dictation right now",
  },
];

export function NotificationController({
  minDelay = 30000, // 30 seconds
  maxDelay = 300000, // 5 minutes
  seed,
  position = "bottom-left",
  enabled = true,
  templates = defaultNotificationTemplates,
  duration = 5000, // 5 seconds default
}: NotificationConfig & { templates: NotificationTemplate[] }) {
  const [currentNotification, setCurrentNotification] = useState<NotificationTemplate | null>(null);

  // Seeded random number generator
  const seededRandom = (min: number, max: number): number => {
    if (!seed) return min + Math.random() * (max - min);
    
    // Simple seeded random number generator
    const x = Math.sin(seed++) * 10000;
    const random = x - Math.floor(x);
    return min + random * (max - min);
  };

  // Get random notification template
  const getRandomTemplate = (): NotificationTemplate => {
    const index = Math.floor(seededRandom(0, templates.length));
    return templates[index];
  };

  // Schedule next notification
  const scheduleNextNotification = () => {
    const delay = Math.floor(seededRandom(minDelay, maxDelay));
    return setTimeout(() => {
      setCurrentNotification(getRandomTemplate());
    }, delay);
  };

  useEffect(() => {
    if (!enabled) {
      setCurrentNotification(null);
      return;
    }

    const timerId = scheduleNextNotification();

    return () => {
      clearTimeout(timerId);
    };
  }, [enabled, currentNotification]);

  if (!currentNotification || !enabled) return null;

  return (
    <NotificationToast
      {...currentNotification}
      position={position}
      duration={duration}
      onClose={() => setCurrentNotification(null)}
    />
  );
} 