'use client';

import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Slider } from "@/components/ui/slider";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { AnimatedNumber } from "./AnimatedNumber";

interface UsageLevel {
  name: string;
  timePerDay: number;
  label: string;
}

const usageLevels: UsageLevel[] = [
  { name: "beginner", timePerDay: 30, label: "Beginner - 30 min per day" },
  { name: "intermediate", timePerDay: 45, label: "Intermediate - 45 min per day" },
  { name: "expert", timePerDay: 60, label: "Expert - 60 min per day" },
];

const WORKING_DAYS_PER_WEEK = 5;
const WORKING_WEEKS_PER_YEAR = 44; // Accounting for holidays and vacation

export function ROICalculator() {
  const [employees, setEmployees] = useState<number>(10);
  const [ratePerHour, setRatePerHour] = useState<number>(400);
  const [selectedLevel, setSelectedLevel] = useState<UsageLevel["name"]>("intermediate");

  const calculateMaxPossibleSavings = (period: "week" | "month" | "year") => {
    const expertLevel = usageLevels.find(level => level.name === "expert");
    if (!expertLevel) return 0;

    const minutesPerDay = expertLevel.timePerDay;
    const hoursPerDay = minutesPerDay / 60;
    const savingsPerDay = hoursPerDay * ratePerHour * employees;

    switch (period) {
      case "week":
        return savingsPerDay * WORKING_DAYS_PER_WEEK;
      case "month":
        return savingsPerDay * WORKING_DAYS_PER_WEEK * 4;
      case "year":
        return savingsPerDay * WORKING_DAYS_PER_WEEK * WORKING_WEEKS_PER_YEAR;
      default:
        return 0;
    }
  };

  const calculateSavings = (period: "week" | "month" | "year") => {
    const selectedUsageLevel = usageLevels.find(level => level.name === selectedLevel);
    if (!selectedUsageLevel) return 0;

    const minutesPerDay = selectedUsageLevel.timePerDay;
    const hoursPerDay = minutesPerDay / 60;
    const savingsPerDay = hoursPerDay * ratePerHour * employees;

    switch (period) {
      case "week":
        return savingsPerDay * WORKING_DAYS_PER_WEEK;
      case "month":
        return savingsPerDay * WORKING_DAYS_PER_WEEK * 4;
      case "year":
        return savingsPerDay * WORKING_DAYS_PER_WEEK * WORKING_WEEKS_PER_YEAR;
      default:
        return 0;
    }
  };

  const handleEmployeesChange = (value: number[]) => {
    if (value[0] !== undefined) {
      setEmployees(value[0]);
    }
  };

  const handleRateChange = (value: number[]) => {
    if (value[0] !== undefined) {
      setRatePerHour(value[0]);
    }
  };

  return (
    <section className="py-20 md:py-28">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
        <div className="text-center mb-12 max-w-3xl mx-auto">
          <h3 className="text-sm font-semibold text-primary uppercase tracking-wide mb-2">
            Return on Investment
          </h3>
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Calculate Your Potential Savings
          </h2>
          <p className="text-lg text-gray-600">
            See how much time and money you could save with Whisperit
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8 items-start max-w-6xl mx-auto">
          <Card className="lg:col-span-4 p-4 sm:p-6">
            <div className="space-y-6">
              <div className="space-y-3">
                <Label className="text-sm">Number of employees: {employees}</Label>
                <Slider
                  value={[employees]}
                  onValueChange={handleEmployeesChange}
                  min={1}
                  max={1000}
                  step={1}
                />
              </div>

              <div className="space-y-3">
                <Label className="text-sm">Rate per hour ($): {ratePerHour}</Label>
                <Slider
                  value={[ratePerHour]}
                  onValueChange={handleRateChange}
                  min={0}
                  max={1000}
                  step={10}
                />
              </div>

              <div className="space-y-3">
                <Label className="text-sm">Whisperit usage level</Label>
                <RadioGroup
                  value={selectedLevel}
                  onValueChange={setSelectedLevel}
                  className="grid gap-2"
                >
                  {usageLevels.map((level) => (
                    <div key={level.name} className="flex items-center space-x-2">
                      <RadioGroupItem value={level.name} id={level.name} />
                      <Label htmlFor={level.name} className="text-sm">{level.label}</Label>
                    </div>
                  ))}
                </RadioGroup>
              </div>
            </div>
          </Card>

          <Card className="lg:col-span-8 p-4 sm:p-6">
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-semibold mb-6">Your Potential Savings</h3>
                <div className="grid gap-8">
                  <div className="flex items-end space-x-6">
                    <div className="flex-1">
                      <div className="flex flex-col mb-3">
                        <span className="text-gray-600 mb-2">Per Week</span>
                        <AnimatedNumber
                          value={Math.round(calculateSavings("week"))}
                          className="text-lg sm:text-xl lg:text-2xl xl:text-3xl font-bold text-[#143C6C]"
                        />
                      </div>
                      <div className="h-28 bg-gray-100 rounded-lg relative">
                        <div 
                          className="absolute bottom-0 left-0 right-0 rounded-lg transition-all duration-500"
                          style={{ 
                            height: `${(calculateSavings("week") / calculateMaxPossibleSavings("year")) * 100}%`,
                            backgroundColor: '#143C6C'
                          }}
                        />
                      </div>
                      <div className="text-center mt-2 text-sm text-gray-500">week</div>
                    </div>
                    <div className="flex-1">
                      <div className="flex flex-col mb-3">
                        <span className="text-gray-600 mb-2">Per Month</span>
                        <AnimatedNumber
                          value={Math.round(calculateSavings("month"))}
                          className="text-lg sm:text-2xl lg:text-3xl xl:text-4xl font-bold text-[#143C6C]"
                        />
                      </div>
                      <div className="h-28 bg-gray-100 rounded-lg relative">
                        <div 
                          className="absolute bottom-0 left-0 right-0 rounded-lg transition-all duration-500"
                          style={{ 
                            height: `${(calculateSavings("month") / calculateMaxPossibleSavings("year")) * 100}%`,
                            backgroundColor: '#143C6C'
                          }}
                        />
                      </div>
                      <div className="text-center mt-2 text-sm text-gray-500">month</div>
                    </div>
                    <div className="flex-1">
                      <div className="flex flex-col mb-3">
                        <span className="text-gray-600 mb-2">Per Year</span>
                        <AnimatedNumber
                          value={Math.round(calculateSavings("year"))}
                          className="text-lg sm:text-3xl lg:text-4xl xl:text-5xl font-bold text-[#143C6C]"
                        />
                      </div>
                      <div className="h-28 bg-gray-100 rounded-lg relative">
                        <div 
                          className="absolute bottom-0 left-0 right-0 rounded-lg transition-all duration-500"
                          style={{ 
                            height: `${(calculateSavings("year") / calculateMaxPossibleSavings("year")) * 100}%`,
                            background: 'linear-gradient(180deg, #4ade80 0%, #22c55e 50%, #16a34a 100%)',
                            boxShadow: '0 0 15px rgba(34, 197, 94, 0.5)'
                          }}
                        />
                      </div>
                      <div className="text-center mt-2 text-sm text-gray-500">year</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6 pt-6 border-t">
                <p className="text-sm text-gray-500">
                  * Calculations based on {WORKING_DAYS_PER_WEEK} working days per week and {WORKING_WEEKS_PER_YEAR} weeks per year
                </p>
              </div>
            </div>
          </Card>
        </div>

        <div className="mt-12 text-center max-w-3xl mx-auto">
          <p className="text-lg text-gray-600 mb-4">
            With {employees} employee{employees !== 1 ? 's' : ''} at a rate of ${ratePerHour} per hour, using Whisperit as a(n){' '}
            {usageLevels.find(level => level.name === selectedLevel)?.label.toLowerCase().split(' - ')[0] ?? 'beginner'}{' '}
            and saving {usageLevels.find(level => level.name === selectedLevel)?.timePerDay ?? 30} minutes per day, you will save:
          </p>
          <div className="text-[#143C6C] text-5xl md:text-6xl font-bold tracking-tight">
            <AnimatedNumber
              value={Math.round(calculateSavings("year"))}
              duration={2500}
            />
          </div>
          <p className="text-lg text-gray-600 mt-2">per year</p>
        </div>
      </div>
    </section>
  );
} 