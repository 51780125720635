"use client";

import { useEffect, useState } from "react";
import { XIcon } from "lucide-react";

export interface NotificationToastProps {
  avatar?: string;
  title: string;
  content: string;
  image?: string;
  position?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
  duration?: number; // Duration in milliseconds
  onClose?: () => void;
}

export function NotificationToast({
  avatar,
  title,
  content,
  image,
  position = "bottom-left",
  duration = 5000,
  onClose,
}: NotificationToastProps) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose?.();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  if (!isVisible) return null;

  const positionClasses = {
    "top-left": "top-2 left-2 sm:top-4 sm:left-4",
    "top-right": "top-2 right-2 sm:top-4 sm:right-4",
    "bottom-left": "bottom-2 left-2 sm:bottom-4 sm:left-4",
    "bottom-right": "bottom-2 right-2 sm:bottom-4 sm:right-4",
  };

  return (
    <div
      className={`fixed ${positionClasses[position]} z-[9999] w-[calc(100%-1rem)] max-w-[400px] animate-slide-in-up sm:w-[400px]`}
    >
      <div className="overflow-hidden rounded-xl bg-white shadow-lg ring-1 ring-black/5">
        <div className="p-3 sm:p-6">
          <div className="flex items-start gap-3 sm:gap-4">
            {avatar && (
              <div className="flex-shrink-0">
                <img
                  className="h-8 w-8 rounded object-cover sm:h-12 sm:w-12"
                  src={avatar}
                  alt="Avatar"
                />
              </div>
            )}
            <div className="min-w-0 flex-1">
              <p className="text-sm font-semibold text-gray-900 sm:text-base">
                {title}
              </p>
              <p className="mt-1 text-xs text-gray-600 sm:text-sm">{content}</p>
              {image && (
                <div className="mt-2 sm:mt-3">
                  <img
                    src={image}
                    alt="Notification image"
                    className="h-24 w-full rounded-lg object-cover sm:h-32"
                  />
                </div>
              )}
            </div>
            <div className="flex flex-shrink-0">
              <button
                type="button"
                className="inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={() => {
                  setIsVisible(false);
                  onClose?.();
                }}
              >
                <span className="sr-only">Close</span>
                <XIcon className="h-4 w-4 sm:h-5 sm:w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
