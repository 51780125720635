import(/* webpackMode: "eager" */ "/vercel/path0/web/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/node_modules/react-fast-marquee/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["CenteredHeroSection"] */ "/vercel/path0/web/src/components/CenteredHeroSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeatureAccordionSection"] */ "/vercel/path0/web/src/components/FeatureAccordionSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/components/Navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotificationController"] */ "/vercel/path0/web/src/components/NotificationController.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProblemAgitation"] */ "/vercel/path0/web/src/components/ProblemAgitation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ROICalculator"] */ "/vercel/path0/web/src/components/ROICalculator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TestimonialSection"] */ "/vercel/path0/web/src/components/TestimonialSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionItem","AccordionTrigger","AccordionContent"] */ "/vercel/path0/web/src/components/ui/accordion.tsx");
